<template>
  <b-card no-body>
    <b-card-header>
      <h3>Vehicle Defects Category List</h3>
    </b-card-header>
    
          <b-card-body>
            <div class="d-flex">
              <b-button
                v-b-modal.modal-center
                class="mr-2"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="onAddVehicleDefect"
              >
                <feather-icon icon="PlusIcon" class="mr-50" />
                Add Vehicle Defects Category
              </b-button>
            </div>
            <br>
            <div class="d-flex justify-content-between flex-wrap">
              <!-- sorting  -->
              <b-form-group
                label-align-sm="left"
                label-cols-sm="2"
                label-for="sortBySelect"
                class="mr-1 mb-md-0"
              >
              </b-form-group>
              <b-form-group
                label="Per Page"
                label-cols="6"
                label-align="left"
                label-size="sm"
                label-for="sortBySelect"
                class="text-nowrap mb-md-0 mr-1"
              >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  inline
                  :options="pageOptions"
                />
              </b-form-group>
            </div>
            </b-card-body>
            <br>
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :per-page="perPage"
                :current-page="currentPage"
                :items="history_list"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(action)="data">
                  <b-button
                    class="mt-1"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="warning"
                    title="Edit Defect Category"
                    @click="onEdit(data.item)"
                    style="
                      position: relative;
                      padding: 8px;
                      width: 31px;
                      margin-top: 0px !important;
                    "
                    v-b-modal.modal-upload-files
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="mr-50"
                      title="Edit Defect Category"
                    />
                  </b-button>
                </template>
              </b-table>
            
            <!--  -->
            
          
          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        

    <b-modal
      size="lg"
      id="modal-center"
      centered
      title="Vehicle Defects Category Info"
      ok-only
      ok-title="Cancel"
      ref="modal-center"
    >
      <b-row>
        <b-col cols="6">
          <b-form-group label="Title" label-for="h-title" label-cols-md="2">
            <b-form-input
              v-model="title"
              id="h-title"
              placeholder="Enter Vehicle Defect Category.."
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Status"
            label-for="h-status-name"
            label-cols-md="2"
          >
            <v-select
              id="h-status-name"
              v-model="status"
              label="label"
              :options="statusOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group
            label=""
            label-for="h-status-name"
            label-cols-md="2"
          >
            <b-button class="btn-sm" variant="primary" @click="onSave">
              Submit
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </b-card>
</template>
            
<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormCheckboxGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormTimepicker,
  BFormDatepicker,
  BDropdownItem,
  BDropdown,
  BLink,
  BPagination,
  BFormSelect,
} from "bootstrap-vue";
import { required, min, confirmed } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { serverUri } from "@/config";
import router from "@/router";
import { getUserData } from "@/auth/utils";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import vSelect from "vue-select";
import axios from "axios";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormCheckbox,
    BFormCheckboxGroup,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    BInputGroup,
    BInputGroupAppend,
    BFormTimepicker,
    BFormDatepicker,
    flatPickr,
    DateRangePicker,
    vSelect,
    BDropdownItem,
    BDropdown,
    BLink,
    BPagination,
    BFormSelect,
  },
  data() {
    return {
      checkbox: false,
      userId: getUserData().driver_id,
      companyId: getUserData().company_id,
      vehicle_defect_id: "",
      status: "",
      title: "",
      statusOptions: [
        { title: "1", label: "Active" },
        { title: "0", label: "Not in Service" },
      ],
      startDate: "",
      endDate: "",
      perPage: 25,
      selectedVehicle: "",
      vehicle: "",
      pageOptions: [5, 10, 15, 25],
      option: [],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      history_list: [],
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      filter: " ",
      fields: [
        // {
        //     key: "main_id",
        //     label: "Main Parent",
        //     formatter: (value) => {
        //       return value == null ? 'No Main Parent' : value;
        //     },
        //   },
        {
          key: "title",
          label: "Title",
          formatter: (value) => {
            return value;
          },
        },
        {
          key: "status",
          label: "Status",
          sortable: false,
          formatter: (value) => {
            return value == 1 ? "Active" : "In-Active";
          },
        },
        {
          key: "action",
        },
      ],
    };
  },
  mounted() {},
  computed: {},
  props: {},
  methods: {
    onEdit(details) 
    {
      this.vehicle_defect_id = details.id;
      this.title             = details.title;
      this.status            = { title: details.status, label: details.status == 1 ? "Active" : 'Not in Service' };
      this.$refs["modal-center"].show();
    },

    getVehicleCaetgories()
    {
        axios.get(`${serverUri}/inspections/vehicle_defects_category/${this.companyId}`).then((res) => {
        console.log(res.data);
        this.history_list = res.data;
        
      });
    },

    onSave() {
        if(this.vehicle_defect_id)
        {

            axios
                .post(`${serverUri}/inspections/vehicle_defects_category/edit`, {
                company_id:this.companyId,
                title: this.title,
                vehicle_defect_id : this.vehicle_defect_id,
                status: this.status.title == undefined ? "" : this.status.title,
                })
                .then((res) => {
                if (res.data.status) {
                    this.showToast(
                    "success",
                    "Success",
                    "The Vehicle defects has been saved."
                    );
                    this.$refs["modal-center"].hide();

                    this.title = "";
                    this.status = "";
                    this.getVehicleCaetgories();
                } else {
                    this.showToast("warning", "Failure", res.data.message);
                }
                });

        } else {
            axios
                .post(`${serverUri}/inspections/vehicle_defects_category/add`, {
                company_id:this.companyId,
                title: this.title,
                status: this.status.title == undefined ? "" : this.status.title,
                })
                .then((res) => {
                if (res.data.status) {
                    this.showToast(
                    "success",
                    "Success",
                    "The Vehicle defects has been saved."
                    );
                    this.$refs["modal-center"].hide();

                    this.title = "";
                    this.status = "";
                    this.getVehicleCaetgories();
                } else {
                    this.showToast("warning", "Failure", res.data.message);
                }
                });
         }
    },

    onAddVehicleDefect() {
        this.vehicle_defect_id = '';
    },

    showToast(variant, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: text,
          variant,
        },
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  created() {
    this.getVehicleCaetgories();
   
  },
};
</script>
            
            <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.edit-header {
  display: flex;
  align-items: center;
}
</style><style>
.permission {
  margin: 10px 0;
}

.permission div {
  margin: 2px 0;
}
button.btn.btn-danger {
  margin-left: 10px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #f22323;
}

input:focus + .slider {
  box-shadow: 0 0 1px #101010;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/* .dropdown {
  top: -20px;
} */
</style>
            